import React from "react"
import { Helmet } from "react-helmet"

import { StaticImage } from "gatsby-plugin-image"

import { LibraryIcon, NewspaperIcon, EmojiHappyIcon, AtSymbolIcon } from '@heroicons/react/solid'

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/formWelcome"
import SectionTab from "src/components/formulaires/sectionTab"
import SectionFaq from "src/components/formulaires/sectionFaq"

const cards = [
  {
    name: 'Sales',
    description: 'Consectetur vel non. Rerum ut consequatur nobis unde. Enim est quo corrupti consequatur.',
    icon: LibraryIcon,
  },
  {
    name: 'Technical Support',
    description: 'Quod possimus sit modi rerum exercitationem quaerat atque tenetur ullam.',
    icon: NewspaperIcon,
  },
  {
    name: 'Media Inquiries',
    description: 'Ratione et porro eligendi est sed ratione rerum itaque. Placeat accusantium impedit eum odit.',
    icon: EmojiHappyIcon,
  },
]

export default function welcome() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Spring Medicare</title>
        <meta name="description" content="Spring Medicare simplifie votre vie au quotidien." />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="relative isolate overflow-hidden bg-gray-200 py-8 lg:py-24">
          <div className="blur-sm absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center">
            <StaticImage
              className="hidden blur-sm absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center lg:block"
              src="../../../images/welcome-praticien.jpg" 
              alt=""
            />
          </div>
          
          <div className="mx-auto max-w-7xl px-4 lg:px-8">
            <div className=" lg:grid lg:grid-cols-2">
              <div className="mx-auto max-w-2xl pb-4 lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-gray sm:text-4xl">Gagnez en visibilité grâce à Spring Medicare</h2>
                <p className="mt-6 text-lg leading-8 text-gray ">
                Votre fiche membre Welcome personnalisée et en ligne
                </p>
                <ul className="mt-6 text-lg leading-8 text-gray list-disc pl-8">
                  <li>
                  Inscription simple, rapide et gratuite
                  </li>
                  <li>
                  Validation de votre candidature par notre équipe
                  </li>
                  <li>
                  Mise en ligne de votre fiche membre
                  </li>
                </ul>
                <p className="mt-6 text-lg leading-8 text-gray">
                Retrouvez sur votre fiche des informations essentielles telles que votre spécialité, les coordonnées de votre cabinet ou encore vos horaires et tarifs. 
                </p>
              </div>
              
              <div>
                <Form />
              </div>
            </div>

            {/* <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
              {cards.map((card) => (
                <div key={card.name} className="backdrop-blur-md flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/50">
                  <card.icon className="h-7 w-5 flex-none text-[#65BCE4]" aria-hidden="true" />
                  <div className="text-base leading-7">
                    <h3 className="font-semibold text-[#4c8caa]">{card.name}</h3>
                    <p className="mt-2 text-gray-500">{card.description}</p>
                  </div>
                </div>
              ))}
            </div> */}
              {/* <p className="mt-3 text-xs leading-6 text-gray">
                Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Koudepouce SAS visant à traiter votre demande. Elles sont conservées pendant au moins 3 ans et sont destinées au service marketing et au service commercial de Koudepouce. Nos mentions légales sont accessibles ici. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en contactant : Koudepouce, Service de la protection des données, 97 Allée Alexandre Borodine, 69800 Saint-Priest. ou par e-mail sur spring-medicare<AtSymbolIcon  className="h-5 inline" />koudepouce.fr en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.
              </p> */}
          </div>
        </div>

        <SectionTab />


        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                Demandes d’informations
              </span>
            </h1>
            {/* <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Internet regorge de conseils et de réponses. Mais, il est souvent difficile d’identifier ce qui est fiable de ce qui ne l’est pas. Koudepouce simplifie la recherche de solutions via internet, en apportant des réponses claires et documentées aux problématiques du quotidien.
            </p>
            <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Koudepouce vous permet aussi d’entrer en contact avec des entreprises de votre choix. Au final, vous obtenez des devis en quelques clics et vous restez libre d’engager qui vous voulez.
            </p> */}
          </div>
        </div>

        <SectionFaq />
        

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}