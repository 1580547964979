import React from "react"
import { GlobeAltIcon, LightningBoltIcon, MailIcon } from '@heroicons/react/outline'
import { CheckIcon, ThumbUpIcon, UserIcon } from '@heroicons/react/solid'

import Img1 from "../../images/spring-medicare-membre-welcome-1.jpg"
import Img2 from "../../images/spring-medicare-membre-welcome-2.jpg"
import Img3 from "../../images/spring-medicare-membre-welcome-3.jpg"


  const tabs = [
    { id: 1, name: 'Annuaire de praticiens', href: '#link1', current: true },
    { id: 2, name: 'Offre évolutive', href: '#link2', current: false },
    { id: 3, name: 'Commencez maintenant', href: '#link3', current: false },
  ]
  
  const relationFeatures = [
    {
      id: 1,
      name: 'Membre Welcome',
      description:
        'Inscrivez vous gratuitement',
      icon: GlobeAltIcon,
    },
    {
      id: 2,
      name: 'Profil personnalisé',
      description:
        "Développer votre visibilité en ligne et votre chiffre d'affaires",
      icon: MailIcon,
    },
    {
      id: 3,
      name: 'Solution sur mesure',
      description:
        'Complétez votre profil avec des fonctions Premium avancées',
      icon: LightningBoltIcon,
    },
  ]

  const timeline = [
    {
      id: 1,
      titre: "Envoi de votre candidature ",
      content: "Pour vous inscrire dans notre annuaire Spring Medicare, vous devez remplir notre formulaire d'inscription. C'est une démarche simple qui peut être réalisée en moins de 5 minutes.",
      href: '/',
      icon: UserIcon,
      iconBackground: 'bg-[#65BCE4]',
    },
    {
      id: 2,
      titre: "Validation de votre profil membre Welcome",
      content: "A réception, notre équipe vérifie vos informations et votre profil. S'il correspond à nos critères de sélection, nous créons votre espace membre et vous envoyons par email vos identifiants pour que vous puissiez personnaliser votre profil (coordonnées, formations, photos...).",
      href: '/',
      icon: ThumbUpIcon,
      iconBackground: 'bg-[#65BCE4]',
    },
    {
      id: 3,
      titre: "Mise en ligne de votre fiche membre",
      content: "Une fois votre fiche membre complétée et validée nous la publions en ligne. Vous recevez le lien de votre fiche par email. Elle vous permettra alors de gagner en visibilité sur internet. ",
      href: '/',
      icon: CheckIcon,
      iconBackground: 'bg-[#65BCE4]',
    },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function resetCurrent() {
    tabs.map((tab) => (
      tab.current = false
    ))
  }

  function setCurrent(tab) {
    tab.current = true
  }
  
  export default function Section3() {
    const [openTab, setOpenTab] = React.useState(1);
    return (
      <div className="max-w-7xl mx-auto py-16">
        <div className="hidden sm:block">
          <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
            {tabs.map((tab, tabIdx) => (
              <a
                onClick={e => {
                    e.preventDefault();
                    setOpenTab(tab.id);
                    resetCurrent();
                    setCurrent(tab);
                }}
                data-toggle="tab"
                role="tablist"
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? 'bg-[#65BCE4]' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5'
                  )}
                />
              </a>
            ))}
          </nav>
        </div>

        <div className="mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
            <div className={openTab === 1 ? "block" : "sm:hidden"} id="link1">
              <div className="relative mt-4 sm:mt-16 lg:mt-8">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <p className="text-base font-semibold text-[#65BCE4] tracking-wide uppercase">Annuaire de praticiens</p>
                    <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Faîtes vous connaître via notre réseau de praticien</h2>
                    <p className="mt-3 text-lg text-gray-500">
                    Spring Medicare a vocation à susciter le dialogue et les échanges confraternels, interprofessionnels et scientifiques entre médecins, thérapeutes et praticiens du bien-être mental ou psychocorporel dans une logique intégrative et holistique, au service du bien-être humain et de la santé.
                    </p>
                    <dl className="mt-10 space-y-10">
                      {relationFeatures.map((item) => (
                        <div key={item.id} className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#65BCE4] text-white">
                              <item.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      height={374}
                      src={Img1}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={openTab === 2 ? "block" : "sm:hidden"} id="link2">
            <div className="relative mt-4 sm:mt-16 lg:mt-8">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <p className="text-base font-semibold text-[#65BCE4] tracking-wide uppercase">Offre évolutive</p>
                    <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Membre Premium, plus qu'une simple fiche en ligne</h2>
                    <p className="mt-3 text-lg text-gray-500">
                    Spring Medicare met à disposition une solution complète pour vous aider à gagner de visibilité, développer votre chiffre d'affaires et gérer votre patientèle.
                    </p>
                    <p className="mt-3 text-lg text-gray-500">
                    La formule Premium est un abonnement sans engagement à partir de 30€ HT/mois
                    </p>
                    <p className="mt-3 text-lg text-gray-500">
                    Vous bénéficiez d'une fiche membre plus performante pour les moteurs de recherche. Vous pouvez mettre en avant votre profil en publiant des articles éditoriaux sur nos sites internet.
                    </p>
                    <p className="mt-3 text-lg text-gray-500">
                    Vos patients ont la capacité de réserver un RDV en ligne (synchronisation avec votre agenda) et ils peuvent régler leur consultation par CB (solution sécurisée).
                    </p>
                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      height={374}
                      src={Img2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={openTab === 3 ? "block" : "sm:hidden"} id="link3">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2 mt-8">
                    <p className="text-base font-semibold text-[#65BCE4] tracking-wide uppercase">Commencez maintenant</p>
                    <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Comment se déroule votre inscription ?</h2>
                    <p className="mt-3 text-lg text-gray-500">
                    Notre projet Spring Medicare vous intéresse ? Pour mieux vous connaître, et préparer votre fiche membre sur notre site, nous vous remercions de remplir un court questionnaire en ligne. Après analyse, vous serez invité à compléter votre profil via un espace membre dédié. Enfin votre fiche membre sera mise en ligne. 
                    </p>
                    
                    <div className="flow-root mt-8">
                      <ul className="-mb-8">
                        {timeline.map((event, eventIdx) => (
                          <li key={event.id}>
                            <div className="relative pb-8">
                              {eventIdx !== timeline.length - 1 ? (
                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                              ) : null}
                              <div className="relative flex space-x-3">
                                <div>
                                  <span
                                    className={classNames(
                                      event.iconBackground,
                                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white text-white'
                                    )}
                                  >
                                    {event.id}
                                  </span>
                                </div>
                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                  <div>
                                    <p className="text-sm text-gray-800">
                                      <strong>{event.titre}{' '}</strong>
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      {event.content}{' '}
                                    </p>
                                  </div>
                                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                    <time dateTime={event.datetime}>{event.date}</time>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>



                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      height={374}
                      src={Img3}
                      alt=""
                    />
                  </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      </div>
    )
  }
  