import React, {useState, useRef} from 'react'
// import { Script } from 'gatsby-script'
// import { MailIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/outline'

const Form = () => {
    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbwpeN2lTnxHutxaWAitcLks5B1QDpAmGxvS9hYZnsSS_RnF_VrIZiJx2vh8BvRDcxfz/exec"
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
        method: 'POST', 
        body: new FormData(formRef.current),

    }).then(res => {
            //console.log("SUCCESSFULLY SUBMITTED")
            window.location.assign('/solution/membre/welcome-validation/')
            setLoading(false)
        })
        .catch(err => console.log(err))
    }

    return (
      <div className="relative bg-white rounded-xl">
        <div className="bg-white py-4 px-4 sm:px-6 lg:col-span-3 lg:py-6 lg:px-6 xl:pl-6 rounded-xl">
          <div className="max-w-lg mx-auto lg:max-w-none">
          <form 
            className="gap-y-6" 
            name="google-sheet"
            ref={formRef} onSubmit={handleSubmit}
          >
              <div className="pb-4">
                <h3 className="text-2xl font-bold text-center pb-4">
                Devenez membre Welcome
                </h3>
                <label htmlFor="nom" className="sr-only">
                  Nom
                </label>
                <input
                  type="text"
                  name="Nom"
                  id="nom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                  placeholder="Nom"
                  required 
                />
              </div>
              <div className="pb-4">
                <label htmlFor="nom" className="sr-only">
                  Prénom
                </label>
                <input
                  type="text"
                  name="Prénom"
                  id="prenom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                  placeholder="Prénom"
                  required 
                />
              </div>
              <div className="pb-4">
                <label htmlFor="cp" className="sr-only">
                  Code postal
                </label>
                <input
                  type="number"
                  name="CodePostal"
                  id="cp"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                  placeholder="Code postal"
                  required 
                />
              </div>
              <div className="pb-4">
                <label htmlFor="telephone" className="sr-only">
                  Téléphone portable
                </label>
                <input
                  type="number"
                  name="Téléphone"
                  id="telephone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                  placeholder="Téléphone portable"
                  required 
                />
              </div>
              <div className="pb-4">
                <label htmlFor="telephone" className="sr-only">
                  Votre spécialité
                </label>
                <input
                  type="text"
                  name="Spécialité"
                  id="specialite"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                  placeholder="Votre spécialité"
                  required 
                />
              </div>
              <div className="pb-4">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="Email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md"
                  placeholder="Email"
                  required 
                />
              </div>
              <div className="pb-4">
                <label htmlFor="message" className="sr-only">
                Objet de votre demande
                </label>
                <textarea
                  id="message"
                  name="Message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border border-gray-300 rounded-md"
                  placeholder="Objet de votre demande"
                  defaultValue={''}
                  required 
                />
              </div>
              <div className="hidden">
                <label htmlFor="tellepro" className="sr-only">
                  Téllépro
                </label>
                <input
                  type="text"
                  name="tellepro"
                  id="tellepro"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Téllépro"
                />
                <input
                  type="text"
                  name="Offre"
                  id="Offre"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Offre"
                  value="Offre Koudepouce Welcome"
                />
                <input
                  type="text"
                  name="Statut"
                  id="Statut"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Statut"
                  value="Nouveau"
                />
                <input
                  type="text"
                  name="Formulaire"
                  id="tellepro"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Formulaire"
                  value="Spring Medicare Welcome"
                />
              </div>
              <div className="pb-4">
                <input 
                    type="checkbox" 
                    id="cgu" 
                    name="CGU" 
                    className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-[#65BCE4] focus:border-[#65BCE4] border-gray-300 rounded-md" 
                    required
                />J'accepte les <a href="/nous-connaitre/juridique/cgu/" className="text-[#65BCE4]" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a>
              </div>
              <div className="pb-4">
                <button
                  type="submit"
                  className="inline-flex items-center mr-6 rounded-md ring-1 ring-spring-bleu bg-spring-bleu px-3.5 py-2.5 text-base font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  {loading ? "Envoi en cours ..." : "Valider"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
}


export default Form