import React from 'react'
import { Disclosure } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "Pourquoi la solution Spring Medicare est parfaite pour votre activité ?",
    answer:
      "Elle vous permet de développer votre visibilité en ligne et bien plus encore. Vos patients peuvent prendre facilement RDV avec vous 24/24. Ils peuvent aussi régler simplement leur consultation par CB.",
  },
  {
    question: "Pourquoi posséder un support digital pour votre cabinet est important ?",
    answer:
      "Près de 4 français sur 10 passent par internet pour réserver un RDV chez un praticien. Nous vous aidons à vous faire connaître davantage et à développer votre patientèle.",
  },
  {
    question: "Quels sont les engagements de Spring Medicare pour protéger vos données et celles de vos patients ?",
    answer:
      "L'accès à notre site est 100% sécurisé. Quoiqu'il en soit en tant qu'intermédiaire nous ne collectons aucune donnée sensible ou médicale.",
  },
  {
    question: "Comment accéder à votre espace personnel Spring Medicare ?",
    answer:
      "Votre espace personnel vous permet d'ajouter et/ou compléter vos informations. Pour éditer votre fiche membre, vous disposez d'identifiants personnels (login et mot de passe). Ils sont transmis sur votre email d'inscription.",
  },
  {
    question: "Quelles sont les fonctionnalités avancées pour gérer votre patientèle ?",
    answer:
      "Via notre offre Premium, nous proposons différents services digitaux pour simplifier votre gestion. Prise de RDV en ligne (synchronisation agenda) et paiement en ligne (CB sécurisé) sont 2 exemples.",
  }
]

export default function SectionFaq() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6  lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}